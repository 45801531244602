import axios from "axios";
import { AlertError, SuccessMsg, toastNotify } from "../../../utils/alert-msg";
import { API } from "../config";
export function addPayment(data, refresh = () => {}) {
  return axios
    .post(API.payment, data)
    .then((res) => {
      if (res) {
        SuccessMsg(res.status);
        refresh();
      }
    })
    .catch((error) => AlertError(error));
}

export function postApiCallDynamic(data, refresh = () => {}, path, header) {
  return axios
    .post(path, data, { headers: { "XTR-API-Action": header } })
    .then((res) => {
      if (res) {
        SuccessMsg(res.status);
        refresh();
      }
    })
    .catch((error) => AlertError(error));
}
export function getApiCallDynamic(param, path) {
  return axios.get(path, { params: param }).catch((error) => AlertError(error));
}
