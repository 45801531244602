import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import {
  Routes,
  Route,
  Redirect,
  useNavigate,
  Navigate,
} from "react-router-dom";

import { withRouter } from "../../utils/with-router";
import { Auth } from "../../api/auth";
import { configureAxios } from "../../api/config";
import IdelTimerContainer from "../../utils/idel-timer";

import { Path } from "./config";
import NotFound from "./pages/not-found/not-found";

import Home from "./pages/home/home";
import Login from "./pages/login/login";
import Dashboard from "./pages/dashboard/dashboard";
import Payment from "./pages/payment/payment";
import PortCount from "./pages/port-count/port-count";
import IpAllocation from "./pages/ip-allocation/ip-allocation";
import Profile from "./pages/setting/profile";
import UserDocument from "./pages/setting/user-document";
import MNPAPI from "./pages/setting/mnp-api";
import UserVerify from "./pages/login/user-verify";

class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        this.props.history(Path.dashboard)

        return <Navigate to={Path.dashboard} />
    }
}

class MNPUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      user: "",
    };

    this.sessionClear = () => {
      localStorage.setItem(process.env.REACT_APP_USER_SESSION, "signout");
      
      configureAxios({ authToken: null, authCallback: this.authCallback });

      this.userHasAuthenticated(false);
      this.props.history.push(Path.login);
    };

    this.logout = () => {
      localStorage.setItem(process.env.REACT_APP_USER_SESSION, "signout");
      Auth.signout();
      
      configureAxios({ authToken: null, authCallback: this.authCallback });

      this.userHasAuthenticated(false);
      this.props.history(Path.login);
    };

    this.authCallback = (response) => {
      localStorage.removeItem(process.env.REACT_APP_SESSION);
      localStorage.setItem(process.env.REACT_APP_USER_SESSION, "signout");
    };

    Auth.setAuthCallback(this.authCallback);

    let result = Auth.currentSession();
    configureAxios({
      authToken: result == null ? null : result.accessToken,
      authCallback: this.authCallback,
    });
  }

  componentWillMount() {
    try {
      let result = Auth.currentSession();
      if (result) {
        this.userHasAuthenticated(true, result);
      }
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }
  }

  userHasAuthenticated = (authenticated, data = "") => {
    this.setState({ isAuthenticated: authenticated, user: data });
  };

  handleLogout = (event) => {
    
    this.logout();
  };

  render() {
 //   console.log("user", JSON.stringify(this.state.user));

  //production cancel out methods
        if (process.env.NODE_ENV === 'production') {
            const noop = () => {}
            ;[
                'assert',
                'clear',
                'count',
                'debug',
                'dir',
                'dirxml',
                'error',
                'exception',
                'group',
                'groupCollapsed',
                'groupEnd',
                'info',
                'log',
                'markTimeline',
                'profile',
                'profileEnd',
                'table',
                'time',
                'timeEnd',
                'timeline',
                'timelineEnd',
                'timeStamp',
                'trace',
                'warn',
            ].forEach((method) => {
                window.console[method] = noop
            })
        }

        const childProps = {
            isAuthenticated: this.state.isAuthenticated,

            user: this.state.user,
            // user :{"data":{"id":1,"sub":"admin","aud":[],"exp":1701703961,"email":"admin@robi.com.bd","firstName":"super","lastName":"admin","isEnable":true,"departments":[{"name":"ims","roles":[{"name":"Super Admin","privileges":["all"]}]},{"name":"approval","roles":[{"name":"Super Admin","privileges":["all"]},{"name":"viewer","privileges":["read","report_generate"]}]}],"iat":1617203961,"jti":"f7e9941c-bc24-424a-a527-472dc662a611"},"accessToken":"eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MSwic3ViIjoiYWRtaW4iLCJhdWQiOltdLCJleHAiOjE3MDE3MDM5NjEsImVtYWlsIjoiYWRtaW5Acm9iaS5jb20uYmQiLCJmaXJzdE5hbWUiOiJzdXBlciIsImxhc3ROYW1lIjoiYWRtaW4iLCJpc0VuYWJsZSI6dHJ1ZSwiZGVwYXJ0bWVudHMiOlt7Im5hbWUiOiJpbXMiLCJyb2xlcyI6W3sibmFtZSI6InN1cGVyX2FkbWluIiwicHJpdmlsZWdlcyI6WyJhbGwiXX1dfSx7Im5hbWUiOiJhcHByb3ZhbCIsInJvbGVzIjpbeyJuYW1lIjoiU3VwZXIgQWRtaW4iLCJwcml2aWxlZ2VzIjpbImFsbCJdfSx7Im5hbWUiOiJ2aWV3ZXIiLCJwcml2aWxlZ2VzIjpbInJlYWQiLCJyZXBvcnRfZ2VuZXJhdGUiXX1dfV0sImlhdCI6MTYxNzIwMzk2MSwianRpIjoiZjdlOTk0MWMtYmMyNC00MjRhLWE1MjctNDcyZGM2NjJhNjExIn0.bHborW9lb9djukwmA3mfaXDio7WGD5nmFZVCFBtFrm7Ky-NfutF1GHXzb_6mt4iXtmV7w2ODvmgNN6kjN_L1SQ","refreshToken":null,"error":null},
            // user :{"data":{"id":7,"sub":"tom","aud":[],"exp":1702048077,"email":"tom@robi.com.bd","firstName":"tom","lastName":"cat","isEnable":true,"departments":[{"name":"approval","roles":[{"name":"Request to BA (Manual)","privileges":["all"]},{"name":"Payment Request","privileges":["all"]},{"name":"BA Approval","privileges":["all"]},{"name":"Super Admin","privileges":["all"]}]}],"iat":1617548077,"jti":"e29e4c1a-4010-4514-94f4-38dde7caa0dd"},"accessToken":"eyJhbGciOiJIUzUxMiJ9.eyJpZCI6Nywic3ViIjoidG9tIiwiYXVkIjpbXSwiZXhwIjoxNzAyMDQ4MDc3LCJlbWFpbCI6InRvbUByb2JpLmNvbS5iZCIsImZpcnN0TmFtZSI6InRvbSIsImxhc3ROYW1lIjoiY2F0IiwiaXNFbmFibGUiOnRydWUsImRlcGFydG1lbnRzIjpbeyJuYW1lIjoiYXBwcm92YWwiLCJyb2xlcyI6W3sibmFtZSI6IlJlcXVlc3QgdG8gQkEgKE1hbnVhbCkiLCJwcml2aWxlZ2VzIjpbImFsbCJdfSx7Im5hbWUiOiJQYXltZW50IFJlcXVlc3QiLCJwcml2aWxlZ2VzIjpbImFsbCJdfSx7Im5hbWUiOiJCQSBBcHByb3ZhbCIsInByaXZpbGVnZXMiOlsiYWxsIl19LHsibmFtZSI6InN1cGVyX2FkbWluIiwicHJpdmlsZWdlcyI6WyJhbGwiXX1dfV0sImlhdCI6MTYxNzU0ODA3NywianRpIjoiZTI5ZTRjMWEtNDAxMC00NTE0LTk0ZjQtMzhkZGU3Y2FhMGRkIn0.AhVrUmqhdBuoJmTz9MVHtEkCa01wBkjAfaKwcnrtoIeFICmSS8V0_xpCHvw5ASSobJ37hXS1GPbTJijCSpC-Sg","refreshToken":null,"error":null},
            userHasAuthenticated: this.userHasAuthenticated,
            handleLogout: this.handleLogout,
            sessionClear: this.sessionClear,
        }
    

     
    if (this.state.isAuthenticated) {
      if (this.props.location.pathname === Path.login) {
        // alert("MNPUser1 - " + childProps.isAuthenticated + " " + this.props.location.pathname);
        return <Navigate to={Path.index} />;
      }
    } else {
      const guestPaths = [Path.login, Path.userVerify];
      if (!guestPaths.includes(this.props.location.pathname)) {
        return <Navigate to={Path.login} />;
      }
    }

    return (
      <Suspense fallback={<></>}>
        <div className="MNPUser ">
          <IdelTimerContainer props={childProps} />
          <Routes>
            <Route
              path={Path.index}
              element={<Index {...this.props} />}
              // render={(routeProps) => (
              //     <Index routeProps={routeProps} />
              // )}
            />

            {/* Admin */}

            <Route
              path={Path.index + "*"}
              element={<Home {...this.props} {...childProps} />}
              // render={(routeProps) => (
              //     <Index routeProps={routeProps} />
              // )}
            >
              <Route path={Path.dashboard} element={<Dashboard />} />
              <Route path={Path.payment} element={<Payment />} />
              <Route path={Path.portCount} element={<PortCount />} />
              <Route path={Path.ipAllocation} element={<IpAllocation />} />




              <Route path={Path.profile} element={<Profile {...this.props} {...childProps} />} />
              <Route path={Path.document} element={<UserDocument />} />
              <Route path={Path.pageApi} element={<MNPAPI />} />


            

              <Route path="*" element={<NotFound />} />
            </Route>


            <Route
              path={Path.login}
              element={<Login {...this.props} {...childProps} />}
            />
            <Route
                  path={Path.userVerify}
                  element={
                      <UserVerify {...this.props} {...childProps} />
                  }
              />
            {/* <Route
              path={Path.registration}
              element={<Registration status={false} {...this.props} {...childProps} />}
            /> */}

            {/* Finally, catch all unmatched routes */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Suspense>
    );
  }
}

export default withRouter(MNPUser);
