import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Auth } from "../../../../api/auth";
import { toastNotify } from "../../../../utils/alert-msg";
import { Path } from "../../config";
import { copyRightCurrentYear } from "../../constant";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      loginPage: true,
      forgetUserName: "",
      passwordShow: false,
    };
  }
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  pageChange = () => {
    this.setState({
      username: "",
      password: "",
      forgetUserName: "",

      loginPage: !this.state.loginPage,
    });
  };
  signIn = () => {
    Auth.signin(this.state.username, this.state.password).then((response) => {
      this.props.userHasAuthenticated(response[0], response[1]);
      this.setState({
        spinner: false,
      });

      if (response[0]) {
        this.props.history(Path.index);
      } else {
        if (response[1] && response[1].error && response[1].error.errorCode) {
          if (response[1].error.errorCode === 2005) {
            alert(response[1].error.errorMessage);
            this.setState({
              isPasswordExpired: true,
            });
          } else {
            toastNotify("error", response[1].error.errorMessage);
          }
        } else {
          toastNotify("error", "Something went wrong. Please try Again!");
        }
      }
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    // let user = ["admin", "zameer"];
    // let pass = ["admin123", "zameer"];
    // if (
    //   user.includes(this.state.username) &&
    //   pass.includes(this.state.password)
    // ) {
    //   this.props.history(Path.dashboard + "/");
    // } else {
    //   toastNotify("error", "Credentials Mismatch");
    // }
    this.signIn();
  };
  handleSubmitForget = (event) => {
    event.preventDefault();
    Auth.forgetPassword(this.state.forgetUserName).then((res) => {
      if (res.status === 200) {
        toastNotify("success", "Check your email");
        this.pageChange();
      }
    });
  };

  render() {
    let forgetPage = (
      <div className="container-fluid">
        <div className="row  login-design">
          <div className="col-auto m-auto">
            <div className="login">
              <img src="/assets/images/logo.png" className="login-logo" />
              <span className="company-name">MNP User Portal</span>
              <br />
              <p className="mt-3">Reset your password</p>
              <div className="input-group-prepend">
                <span className="input-group-text">Email</span>
                <input
                  type="email"
                  className="form-control login-form"
                  id="forgetUserName"
                  required
                  value={this.state.forgetUserName}
                  onChange={this.handleChange}
                />
              </div>

              <button
                className="btn btn-sm btn-outline-primary m-2"
                onClick={this.handleSubmitForget}
              >
                Reset
              </button>
              <br />
              <small>
                Already have an account?{" "}
                <Link to="#" onClick={this.pageChange}>
                  Sign in
                </Link>
              </small>
              <br />
              {/* <small>
                New Here? <Link to={"/registration"}>Register</Link>
              </small> */}
            </div>
          </div>
        </div>
      </div>
    );
    let { loginPage } = this.state;
    return (
      <>
        {loginPage ? (
          <div className="container-fluid">
            <div className="row  login-design">
              <div className="col-auto m-auto">
                <div className="login">
                  <img src="/assets/images/logo.png" className="login-logo" />
                  <span className="company-name">MNP User Portal</span>
                  <form onSubmit={this.handleSubmit}>
                    <div className="input-group my-3">
                      <div className="input-group-prepend">
                        <i className="input-group-text fa fa-user"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Username"
                        id="username"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <i className="input-group-text fa fa-lock"></i>
                      </div>
                      <input
                        type={this.state.passwordShow ? "text" : "password"}
                        className="form-control"
                        placeholder="Password"
                        id="password"
                        onChange={this.handleChange}
                      />
                      <div className="input-group-prepend">
                        <i
                          className={`input-group-text ${
                            this.state.passwordShow
                              ? "fa fa-eye-slash"
                              : "fa fa-eye"
                          }`}
                          onClick={() => {
                            this.setState({
                              passwordShow: !this.state.passwordShow,
                            });
                          }}
                        ></i>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-sm btn-outline-primary m-2"
                    >
                      Sign In
                    </button>
                  </form>
                  <br />
                  <small>
                    {/* New Here? <Link to={"/registration"}>Register</Link> */}
                  </small>
                  <br />
                  <small>
                    Forget Password?{" "}
                    <Link to="#" onClick={this.pageChange}>
                      Click here
                    </Link>
                  </small>
                </div>
              </div>
            </div>
          </div>
        ) : (
          forgetPage
        )}
        {/* <!-- Footer --> */}
        <footer className="footer-credit">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <small>
                  © 2018-{copyRightCurrentYear} Infozillion Teletech BD. All Rights Reserved.
                </small>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}
