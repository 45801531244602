/**
 * Path definition to route between JavaScript pages.
 * Paths can be accessed through Path's static
 * members, e.g., Path.index etc.
 * @type {string}
 */
const INDEX = "/";
const NOTFOUND = "/notfound";

const LOGIN = "/login";
const REGISTRATION = "/registration";

const DASHBOARD = "dashboard";

const PAYMENT = "payment";
const PORT_COUNT = "port-count";
const IP_ALLOCATION = "ip-allocation";

const PROFILE = "profile";
const DOCUMENT = "document";
const PAGEAPI = "api";
const USERVERIFY = "/verify";

export class Path {
  static get userVerify() {
    return USERVERIFY;
  }
  static get profile() {
    return PROFILE;
  }
  static get document() {
    return DOCUMENT;
  }
  static get pageApi() {
    return PAGEAPI;
  }

  static get ipAllocation() {
    return IP_ALLOCATION;
  }

  static get portCount() {
    return PORT_COUNT;
  }

  static get payment() {
    return PAYMENT;
  }

  static get dashboard() {
    return DASHBOARD;
  }
  static get index() {
    return INDEX;
  }

  static get login() {
    return LOGIN;
  }

  static get registration() {
    return REGISTRATION;
  }

  static get notFound() {
    return NOTFOUND;
  }
}

const API_FILE_MANaGMENT = "dm/api/v1/local/files";
const API_DEEPING_SUMMERY = "";

const API_PAYMENT = "mnp-setup/api/v1/mnp/clientpayment";
const API_IP = "mnp-setup/api/v1/mnp/ipaddress";

const API_REQUEST_COUNT = "mnp-setup/api/v1/mnp/requestcount";

const API_OWN_INFO = "mnp-setup/api/v1/mnp/userdetails";

const API_BUSINESS_USER = "mnp-setup/api/v1/mnp/userlist";
const API_OPEN_REGISTRATION = "mnp-setup/api/v1/mnp/openregistration";

const API_TPS = "mnp-setup/api/v1/mnp/tps";

const API_SEARCH_PORT = "mnp-setup/api/v1/mnp/portcount";

// api path

export class API {
  static get tps() {
    return API_TPS;
  }
  static get openRegistration() {
    return API_OPEN_REGISTRATION;
  }
  static get ownInfo() {
    return API_OWN_INFO;
  }
  static get requestCount() {
    return API_REQUEST_COUNT;
  }

  static get fileManagement() {
    return API_FILE_MANaGMENT;
  }
  static get payment() {
    return API_PAYMENT;
  }

  static get businessUser() {
    return API_BUSINESS_USER;
  }

  static get ipAllocation() {
    return API_IP;
  }
  static get user() {
    return API_USER;
  }

  static get deepingSummary() {
    return API_DEEPING_SUMMERY;
  }

  static get portCount() {
    return API_SEARCH_PORT;
  }
}
