import logo from "./logo.svg";
import "./App.css";
import { useState } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { modules } from "./modules";
import NotFound from "./not-found/not-found";
function App() {
  const [currentTab, setCurrentTab] = useState("dashboard");

  if (process.env.NODE_ENV === "production") {
    const noop = () => {};
    [
      "assert",
      "clear",
      "count",
      "debug",
      "dir",
      "dirxml",
      "error",
      "exception",
      "group",
      "groupCollapsed",
      "groupEnd",
      "info",
      "log",
      "markTimeline",
      "profile",
      "profileEnd",
      "table",
      "time",
      "timeEnd",
      "timeline",
      "timelineEnd",
      "timeStamp",
      "trace",
      "warn",
    ].forEach((method) => {
      window.console[method] = noop;
    });
  }

  return (
    <Routes>
      {modules.map((module) => {
        return <Route {...module.routeProps} key={module.name} />;
      })}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
