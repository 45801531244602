import React, { Component } from "react";
import { ModalFooter } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

export const EVENT_MODEL_CLOSE = {
  target: { id: "show" },
};
export const ModalCommon = (props) => {
  return (
    <Modal
      centered
      size={props.size}
      className="scroll4"
      show={props.show}
      onHide={(e) => {
        props.handleClose(EVENT_MODEL_CLOSE);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      open
    >
      <Modal.Header>
        <div>
          <div style={{ float: "left" }}>
            <img id="1" src="/assets/images/eDoc.png" height="30" />
          </div>
        </div>
        <Modal.Title className="w-100">
          <div className="text-center ">
            <h1 className="h4 text-gray-900">
              {props.tittle ? props.tittle : "Information Update"}
            </h1>
          </div>
        </Modal.Title>
        <button
          id={"show"}
          type="button"
          className="close float-right"
          onClick={props.handleClose}
          aria-label="Close"
        >
          <span id={"show"} aria-hidden="true">
            &times;
          </span>
        </button>
      </Modal.Header>
      <Modal.Body className="l-bg">{props.load}</Modal.Body>
    </Modal>
  );
};

export const ModalCommonHooks = (props) => {
  return (
    <Modal
      centered
      size={props.size}
      className="scroll4"
      show={props.show}
      // onHide={(e) => {
      //   props.setShow(false);
      // }}
      aria-labelledby="contained-modal-title-vcenter"
      open
    >
      <Modal.Header>
        <div>
          <div style={{ float: "left" }}>
            {/* <img id="1" src="/assets/images/eDoc.png" height="30" /> */}
          </div>
        </div>
        <Modal.Title className="w-100">
          <div className="text-center ">
            {" "}
            <h1 className="h4 text-gray-900">
              <u> {props.tittle ? props.tittle : "Information Update"}</u>
            </h1>
          </div>
        </Modal.Title>
        <button
          id={"show"}
          type="button"
          className="close float-right"
          onClick={(e) => props.setShow(false)}
          aria-label="Close"
        >
          <span id={"show"} aria-hidden="true">
            &times;
          </span>
        </button>
      </Modal.Header>
      <Modal.Body className="l-bg">{props.load}</Modal.Body>
    </Modal>
  );
};

// export const ModalCommon1 = (props) => {
//     return (
//         <Modal
//             centered
//             size={props.size}
//             className="modal fade scroll4"
//             show={props.show}
//             onHide={(e) => {
//                 props.handleClose(EVENT_MODEL_CLOSE)
//             }}
//             aria-labelledby="contained-modal-title-vcenter"
//             open
//         >
//             <Modal.Header>
//                 <div>
//                     <div style={{ float: 'left' }}>
//                         <img id="1" src="/assets/images/eDoc.png" height="30" />
//                     </div>
//                 </div>
//                 <Modal.Title className="w-100">
//                     <div className="text-center ">
//                         <h1 className="h4 text-gray-900">
//                             {props.tittle ? props.tittle : 'Information Update'}
//                         </h1>
//                     </div>
//                 </Modal.Title>
//                 <button
//                     id={'show'}
//                     type="button"
//                     className="close float-right"
//                     onClick={props.handleClose}
//                     aria-label="Close"
//                 >
//                     <span id={'show'} aria-hidden="true">
//                         &times;
//                     </span>
//                 </button>
//             </Modal.Header>
//             <Modal.Body className={`l-bg ${props.bodyStyle}`}>
//                 {props.load}
//             </Modal.Body>
//         </Modal>
//     )
// }

// <div
// className="modal fade"
// id="modalcreate"
// tabindex="-1"
// role="dialog"
// aria-labelledby="exampleModalCenterTitle"
// aria-hidden="true"
// >
// <div
//     className="modal-dialog modal-dialog-centered"
//     role="document"
// >
//     <div className="modal-content">

//         <div className="modal-body">
//             <form className="p-3">
//                 <div className="form-row">
//                     <div className="form-group col-12 d-flex">
//                         <label className="w-40 text-right pr-2">
//                             Name
//                         </label>
//                         <input
//                             type="text"
//                             className="form-control form-control-sm w-60"
//                         />
//                     </div>
//                     <div className="form-group col-12 d-flex">
//                         <label className="w-40 text-right pr-2">
//                             Email
//                         </label>
//                         <input
//                             type="email"
//                             className="form-control form-control-sm w-60"
//                         />
//                     </div>
//                     <div className="form-group col-12 d-flex">
//                         <label className="w-40 text-right pr-2">
//                             User Type
//                         </label>
//                         <select className="form-control form-control-sm w-60">
//                             <option></option>
//                             <option>Admin</option>
//                             <option>M&S AQL Editor</option>
//                             <option>M&S AQL Viewer</option>
//                             <option>Factory CA</option>
//                         </select>
//                     </div>
//                     <div className="form-group col-12 d-flex">
//                         <label className="w-40 text-right pr-2">
//                             Date of Join
//                         </label>
//                         <input
//                             type="date"
//                             className="form-control form-control-sm w-60"
//                         />
//                     </div>
//                     <div className="form-group col-12 d-flex">
//                         <label className="w-40 text-right pr-2">
//                             Supplier Name
//                         </label>
//                         <input
//                             type="text"
//                             className="form-control form-control-sm w-60"
//                         />
//                     </div>
//                     <div className="form-group col-12 d-flex">
//                         <label className="w-40 text-right pr-2">
//                             Series Number
//                         </label>
//                         <input
//                             type="text"
//                             className="form-control form-control-sm w-60"
//                         />
//                     </div>
//                     <div className="form-group col-12 d-flex">
//                         <label className="w-40 text-right pr-2">
//                             Certification Date
//                         </label>
//                         <input
//                             type="date"
//                             className="form-control form-control-sm w-60"
//                         />
//                     </div>
//                     <div className="form-group col-12 d-flex">
//                         <label className="w-40 text-right pr-2">
//                             Deactivate Date
//                         </label>
//                         <input
//                             type="date"
//                             className="form-control form-control-sm w-60"
//                         />
//                     </div>
//                     <div className="form-group col-12 d-flex">
//                         <label className="w-40 text-right pr-2">
//                             Default Audit Country
//                         </label>
//                         <select className="form-control form-control-sm w-60">
//                             <option></option>
//                             <option>America</option>
//                             <option>Bangladesh</option>
//                             <option>Srilanka</option>
//                         </select>
//                     </div>
//                     <div className="form-group col-12 d-flex">
//                         <label className="w-40 text-right pr-2">
//                             Default Audit Type
//                         </label>
//                         <select className="form-control form-control-sm w-60">
//                             <option></option>
//                             <option>Clothing</option>
//                             <option>Lingerie</option>
//                             <option>Homeware</option>
//                         </select>
//                     </div>
//                 </div>
//             </form>
//         </div>
//         <div className="modal-footer justify-content-center mt-3">
//             <button
//                 type="button"
//                 className="btn btn-sm btn-outline-primary"
//                 data-dismiss="modal"
//             >
//                 Add
//             </button>
//             <button
//                 type="button"
//                 className="btn btn-sm btn-outline-danger"
//                 data-dismiss="modal"
//             >
//                 Discard
//             </button>
//         </div>
//     </div>
// </div>
// </div>
// </div>
