import { element } from "prop-types";
import React from "react";

export default function CardView(props) {
  const { html, buttonName, value, set } = props;

  return (
    <div className="card">
      <div className="card-header" id="headingOne">
        <h2 className="mb-0">
          <button
            className={
              value === true
                ? "btn btn-full btn-link touch-dropdown-collapse "
                : "btn btn-full btn-link touch-dropdown"
            }
            id={value}
            onClick={() => {
              set(!value);
            }}
            type="button"
            data-toggle="collapse"
            data-target="#id"
            aria-expanded="true"
            aria-controls="id"
          >
            {buttonName}
          </button>
        </h2>
      </div>
      <div
        id={value}
        className={`collapse ${value === true ? "show" : ""}`}
        aria-labelledby="headingOne"
        data-parent="#accordionExample"
      >
        <div className="card-body">{html}</div>
      </div>
    </div>
  );
}
