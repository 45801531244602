import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import useInput from "../../../../utils/components/custome-hooks/use-input";
import { toastNotify } from "../../../../utils/alert-msg";
import UploadService from "../../../../utils/file-upload-download";

import Select from "react-dropdown-select";
import LoadingOverlay from "react-loading-overlay";

import { ModalCommonHooks } from "../../../../utils/components/modal";
import { API } from "../../config";
import {
  getApiCallDynamic,
  postApiCallDynamic,
} from "../../server-call/event-api";
import { DownloadComponent } from "../../../../utils/components/download-component";
import "./label.css";

// import { Auth } from "../../../../api/auth";
export default function UserDocument(props) {
  let navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  let [spinner, setSpinner] = useState(false);
  let [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  let [data, setData] = useState(null);

  let [tradeLicense] = useInput({
    data: null,

    status: props.status,
    initial: null,
  });

  let [tinCertificate] = useInput({
    data: null,

    status: props.status,
    initial: null,
  });

  let [nid] = useInput({
    data: null,

    status: props.status,
    initial: null,
  });
  let [bin] = useInput({
    data: null,

    status: props.status,
    initial: null,
  });

  let [other, setOther] = useState(() => {
    if (props.status) {
      return props.data.other;
    } else {
      return [];
    }
  });

  let [clientId] = useInput({
    data: "",
    status: false,
    initial: "",
  });
  let [userId] = useInput({
    data: "",
    status: false,
    initial: "",
  });
  useEffect(() => {
    getApiCallDynamic({}, API.ownInfo).then((res) => {
      if (res?.status === 200) {
        let apiData = res.data.dataList[0];
        setData(apiData);
        tradeLicense.saveValue(
          apiData.tradeLicense == "" ? null : apiData.tradeLicense
        );
        tinCertificate.saveValue(
          apiData.tinCertificate == "" ? null : apiData.tinCertificate
        );
        nid.saveValue(apiData.nid == "" ? null : apiData.nid);
        bin.saveValue(apiData.bin == "" ? null : apiData.bin);
        setOther(apiData.other);
        clientId.saveValue(apiData.clientId);
        userId.saveValue(apiData.userId);
      }
    });
  }, []);

  const handleSubmit = () => {
    let data = {
      action: "update",
      payload: {
        userId: userId.value,

        tinCertificate: tinCertificate.value,
        tradeLicense: tradeLicense.value,
        nid: nid.value,
        bin: bin.value,
        other: other,
      },
    };
    postApiCallDynamic(data, handleClose, API.businessUser);
  };

  const handleValidation = (values) => {
    let errors = {};

    return errors;
  };

  const fileChecker = (event) => {
    let blnValid = false;
    let _validFileExtensions = [
      ".png",
      ".jpg",
      ".jpeg",
      ".csv",
      ".docx",
      ".doc",
      ".xlx",
      ".xlsx",
      ".pdf",
    ];
    // if()
    for (var j = 0; j < _validFileExtensions.length; j++) {
      var sCurExtension = _validFileExtensions[j];
      if (
        event.target.files[0].name
          .substr(
            event.target.files[0].name.length - sCurExtension.length,
            sCurExtension.length
          )
          .toLowerCase() == sCurExtension.toLowerCase()
      ) {
        blnValid = true;
        break;
      }
    }
    return blnValid;
  };
  const handleChange =
    (val, prevData, multi = false) =>
    (event) => {
      if (!fileChecker(event)) {
        toastNotify("warn", "File extension missmatch");

        return;
      }
      let fileSize = event.target.files[0].size / 1048576;

      if (fileSize > 200) {
        toastNotify("error", "File size can not be grater than 200mb");
        return;
      }

      let currentFile = event.target.files[0];
      setProgress(0);
      UploadService.uploadFile(
        currentFile,
        (event) => {
          setProgress(Math.round((100 * event.loaded) / event.total));
        },
        API.fileManagement
      )
        .then((res) => {
          if (res.status === 200) {
            toastNotify("success", "Successfully uploaded");
            if (multi) {
              let data = [...prevData, res.data];
              val(data);
            } else {
              val.saveValue(res.data);
            }

            setProgress(0);
          }
        })
        .catch((err) => {
          toastNotify("error", "failed to uploaded");

          setProgress(0);
        });

      // setProfileImg(URL.createObjectURL(event.target.files[0]))
    };

  const handleFileDownload = (event) => {
    let data = event.target.id;

    UploadService.downloadReportServer(API.fileManagement, {
      fileToDownload: data,
    });
  };

  const removeFile = (list, index, setter) => (event) => {
    list.splice(index, 1);

    let numbersCopy = [...list];
    setOther(numbersCopy);
  };

  return (
    <>
      <Formik
        initialValues={{}}
        validate={(values) => handleValidation(values)}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit();
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,

          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <Outlet />

            <div
              className="container-fluid"
              style={{ height: "calc(100vh - 110px)" }}
            >
              <div className=" row h-100">
                <div className="col-12 col-md-4 m-auto text-center  ">
                  <LoadingOverlay
                    active={spinner}
                    // spinner={<BounceLoader />}
                    spinner={true}
                    text="Please wait a moment"
                  >
                    <Form className="p-3">
                      <div className="row shadow my-4 border border-primary p-2">
                        <div className="col col-md-4 py-1 font-weight-bold">
                          <label>Trade License</label>
                        </div>
                        {tradeLicense.value == null ? (
                          <div className="col col-md-8 py-1">
                            <label className="pr-2">No document</label>
                          </div>
                        ) : (
                          <div className="col col-md-8 py-1">
                            <label
                              style={{ cursor: "pointer" }}
                              title="Download"
                              className="pr-2"
                            >
                              <input
                                className="d-none"
                                id={tradeLicense.value}
                                value={tradeLicense.value}
                                onClick={handleFileDownload}
                              />
                              {tradeLicense.value.split(/_(.+)/)[1]}
                              <i
                                className="fa fa-download text-primary pl-2"
                                title="Download"
                              ></i>
                            </label>
                          </div>
                        )}
                      </div>
                      <div className="row shadow my-4 border border-primary p-2">
                        <div className="col col-md-4 py-1 font-weight-bold">
                          <label>TIN Certificate</label>
                        </div>
                        {tinCertificate.value == null ? (
                          <div className="col col-md-8 py-1">
                            <label className="pr-2">No document</label>
                          </div>
                        ) : (
                          <div className="col col-md-8 py-1">
                            <label
                              style={{ cursor: "pointer" }}
                              title="Download"
                              className="pr-2"
                            >
                              <input
                                className="d-none"
                                id={tinCertificate.value}
                                value={tinCertificate.value}
                                onClick={handleFileDownload}
                              />
                              {tinCertificate.value.split(/_(.+)/)[1]}
                              <i
                                className="fa fa-download text-primary pl-2"
                                title="Download"
                              ></i>
                            </label>
                          </div>
                        )}
                      </div>
                      <div className="row shadow my-4 border border-primary p-2">
                        <div className="col col-md-4 py-1 font-weight-bold">
                          <label>NID</label>
                        </div>
                        {nid.value == null ? (
                          <div className="col col-md-8 py-1">
                            <label className="pr-2">No document</label>
                          </div>
                        ) : (
                          <div className="col col-md-8 py-1">
                            <label
                              style={{ cursor: "pointer" }}
                              title="Download"
                              className="pr-2"
                            >
                              <input
                                className="d-none"
                                id={nid.value}
                                value={nid.value}
                                onClick={handleFileDownload}
                              />
                              {nid.value.split(/_(.+)/)[1]}
                              <i
                                className="fa fa-download text-primary pl-2"
                                title="Download"
                              ></i>
                            </label>
                          </div>
                        )}
                      </div>

                      <div className="row shadow my-4 border border-primary p-2">
                        <div className="col col-md-4 py-1 font-weight-bold">
                          <label>BIN</label>
                        </div>
                        {bin.value == null ? (
                          <div className="col col-md-8 py-1">
                            <label className="pr-2">No document</label>
                          </div>
                        ) : (
                          <div className="col col-md-8 py-1">
                            <label
                              style={{ cursor: "pointer" }}
                              title="Download"
                              className="pr-2"
                            >
                              <input
                                className="d-none"
                                id={bin.value}
                                value={bin.value}
                                onClick={handleFileDownload}
                              />
                              {bin.value.split(/_(.+)/)[1]}
                              <i
                                className="fa fa-download text-primary pl-2"
                                title="Download"
                              ></i>
                            </label>
                          </div>
                        )}
                      </div>
                      <div className="row shadow my-4 border border-primary p-2">
                        <div className="col m-auto col-md-4 py-1 font-weight-bold">
                          <label>Others</label>
                        </div>
                        <div className="col col-md-8 py-1">
                          {/* <div className="input-group  "> */}
                          {other.length !== 0
                            ? other.map((i, index) => (
                                <label
                                  style={{ cursor: "pointer" }}
                                  title="Download"
                                  className="pr-2"
                                >
                                  <input
                                    className="d-none"
                                    id={i}
                                    value={i}
                                    onClick={handleFileDownload}
                                  />
                                  {i.split(/_(.+)/)[1]}
                                  <i
                                    className="fa fa-download text-primary pl-2"
                                    title="Download"
                                  ></i>
                                </label>
                              ))
                            : null}
                          {/* </div> */}
                          {other.length !== 0 ? null : "No document"}
                        </div>
                      </div>
                      <div className="m-4">
                        <a
                          href="#"
                          className="my-2"
                          onClick={() => {
                            setShow(true);
                          }}
                        >
                          Update Document
                        </a>
                      </div>
                    </Form>
                  </LoadingOverlay>

                  {/* <!-- Modal Create --> */}
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
      <ModalCommonHooks
        load={
          <Formik
            initialValues={{}}
            validate={(values) => handleValidation(values)}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit();
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,

              handleSubmit,
              isSubmitting,
            }) => (
              <>
                <Form className="my-3">
                  <div className="row">
                    <div className="col py-1">
                      <label>Trade License</label>
                    </div>
                    <div className="col py-1">
                      <input
                        type="file"
                        className="form-control form-control-sm"
                        placeholder="abd@example.com"
                        onChange={handleChange(tradeLicense)}
                      />
                      <small
                        className="text-danger"
                        style={{ fontSize: "10px" }}
                      >
                        <i className="fa fa-close"></i>Valid formate: png, jpg,
                        jpeg, csv, docx, doc, xlx, xlsx, pdf
                      </small>
                      <DownloadComponent
                        state={tradeLicense}
                        handleFileDownload={handleFileDownload}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col py-1">
                      <label>TIN Certificate</label>
                    </div>
                    <div className="col py-1">
                      <input
                        type="file"
                        className="form-control form-control-sm"
                        placeholder="01XXXXXXXXX"
                        onChange={handleChange(tinCertificate)}
                      />
                      <small
                        className="text-danger"
                        style={{ fontSize: "10px" }}
                      >
                        <i className="fa fa-close"></i>Valid formate: png, jpg,
                        jpeg, csv, docx, doc, xlx, xlsx, pdf
                      </small>
                      <DownloadComponent
                        state={tinCertificate}
                        handleFileDownload={handleFileDownload}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col py-1">
                      <label>NID</label>
                    </div>
                    <div className="col py-1">
                      <input
                        type="file"
                        className="form-control form-control-sm"
                        placeholder="01XXXXXXXXX"
                        onChange={handleChange(nid)}
                      />
                      <small
                        className="text-danger"
                        style={{ fontSize: "10px" }}
                      >
                        <i className="fa fa-close"></i>Valid formate: png, jpg,
                        jpeg, csv, docx, doc, xlx, xlsx, pdf
                      </small>
                      <DownloadComponent
                        state={nid}
                        handleFileDownload={handleFileDownload}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col py-1">
                      <label>bin</label>
                    </div>
                    <div className="col py-1">
                      <input
                        type="file"
                        className="form-control form-control-sm"
                        placeholder="01XXXXXXXXX"
                        onChange={handleChange(bin)}
                      />
                      <small
                        className="text-danger"
                        style={{ fontSize: "10px" }}
                      >
                        <i className="fa fa-close"></i>Valid formate: png, jpg,
                        jpeg, csv, docx, doc, xlx, xlsx, pdf
                      </small>
                      <DownloadComponent
                        state={bin}
                        handleFileDownload={handleFileDownload}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col py-1">
                      <label>Others</label>
                    </div>
                    <div className="col py-1">
                      <input
                        type="file"
                        className="form-control form-control-sm"
                        placeholder="01XXXXXXXXX"
                        onChange={handleChange(setOther, other, true)}
                      />
                      <small
                        className="text-danger"
                        style={{ fontSize: "10px" }}
                      >
                        <i className="fa fa-close"></i>Valid formate: png, jpg,
                        jpeg, csv, docx, doc, xlx, xlsx, pdf
                      </small>
                      {/* {other.map((i) => (
                              <label>{i}</label>
                            ))} */}

                      <div className="input-group  ">
                        {other.length !== 0
                          ? other.map((i, index) => (
                              <small className="c-span-round ">
                                <span
                                  className="c-link"
                                  style={{ textAlign: "center" }}
                                  id={i}
                                  value={i}
                                  onClick={handleFileDownload}
                                  title="Download"
                                >
                                  {i.split(/_(.+)/)[1]}
                                </span>
                                <span>
                                  <label className=" m-0 pl-1 c-link ">
                                    <input
                                      className="d-none"
                                      value={index}
                                      id={"other"}
                                      onClick={removeFile(
                                        other,
                                        index,
                                        setOther
                                      )}
                                    />{" "}
                                    <i
                                      className="fa fa-times text-danger"
                                      title="Delete"
                                    ></i>
                                  </label>
                                </span>
                              </small>
                            ))
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer justify-content-center mt-3">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-danger"
                      onClick={handleClose}
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      className="btn btn-sm btn-outline-primary"
                    >
                      Update
                    </button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        }
        tittle={"Document Upload"}
        show={show}
        setShow={handleClose}
        size={"lg"}
      />
    </>
  );
}
