import React, { Component } from "react";
import { Outlet, Route, Routes } from "react-router";

import TopMenu from "./top-menu";
import { copyRightCurrentYear } from "../../constant";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <TopMenu {...this.props} />

        <div className="content-body">
          <Outlet />
        </div>

        {/* <!-- Footer --> */}
        <footer className="footer-credit">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <small>
                  © 2018-{copyRightCurrentYear} Infozillion Teletech BD. All Rights Reserved.
                </small>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}
