import React, { useRef } from "react";
import { API } from "../../config";
import {
  getDeepingSearchPortCountColumn,
  getDeepingSummaryColumn,
} from "../../constant";

import PortCountPagination from "./port-count-pagination";

export default function PortCount(props) {
  let reference = useRef(null);

  const handleSelectedRow = (row, isSelect, rowIndex, e) => {
    // console.log(
    //   "row====>",
    //   row,
    //   "isSelected=>>>>",
    //   isSelect,
    //   "rowIndex=>>>>>>>",
    //   rowIndex,
    //   "Event=>>>>",
    //   e
    // );
    // if (e.target.id === "view") {
    //   setShow(true);
    //   setData(row);
    //   setStatus(true);
    //   setPermission("view");
    // } else if (e.target.id === "update") {
    //   setShow(true);
    //   setData(row);
    //   setPermission("update");
    //   setStatus(true);
    // } else if (e.target.id === "delete") {
    //   let data = {
    //     action: "delete",
    //     payload: {
    //       name: row.name,
    //     },
    //   };
    //   crudRegion(data, handleClose);
    // }
  };
  const selectRow = {
    mode: "radio",
    hideSelectColumn: true, // enable hide selection column.

    clickToSelect: true,
    onSelect: handleSelectedRow,
  };
  return (
    <div className="container-fluid">
      <div className="row pt-4">
        <div className="col-12 text-center">{/* <h2>IP List</h2> */}</div>
      </div>
      <div className="row pt-4">
        <div className="col-12">
          <PortCountPagination
            ref={reference}
            selectRow={selectRow}
            columns={getDeepingSearchPortCountColumn}
            path={API.portCount}
          />
        </div>
      </div>
    </div>
  );
}
