import React, { useRef } from "react";
import { API } from "../../config";
import { getIPColumn } from "../../constant";
import IPPagination from "./ip-pagination";
import useInput from "../../../../utils/components/custome-hooks/use-input";
import { addIpAddress } from "../../mnp-user-api-hanlder";
import { ErrorMessage, Form, Formik } from "formik";
import { toastNotify } from "../../../../utils/alert-msg";
import { postApiCallDynamic } from "../../server-call/event-api";

export default function IpAllocation(props) {
  let reference = useRef(null);
  let ipRef = useRef(null);

  const handleSelectedRow = (row, isSelect, rowIndex, e) => {
    // console.log(
    //   "row====>",
    //   row,
    //   "isSelected=>>>>",
    //   isSelect,
    //   "rowIndex=>>>>>>>",
    //   rowIndex,
    //   "Event=>>>>",
    //   e
    // );
    // if (e.target.id === "view") {
    //   setShow(true);
    //   setData(row);
    //   setStatus(true);
    //   setPermission("view");
    // } else if (e.target.id === "update") {
    //   setShow(true);
    //   setData(row);
    //   setPermission("update");
    //   setStatus(true);
    // } else if (e.target.id === "delete") {
    //   let data = {
    //     action: "delete",
    //     payload: {
    //       name: row.name,
    //     },
    //   };
    //   crudRegion(data, handleClose);
    // }
  };

  let [ipAddress] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  const selectRow = {
    mode: "radio",
    hideSelectColumn: true, // enable hide selection column.

    clickToSelect: true,
    onSelect: handleSelectedRow,
  };
  const addIp = () => {
    let data = {
      action: "create",
      payload: {
        ipAddress: ipAddress.value,
        status: "Pending",
      },
    };
    postApiCallDynamic(data, reference.current.retrieveList, API.ipAllocation);
    ipRef.current.clear();
  };

  const handleValidation = (values) => {
    let errors = {};
    var ipformat =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    if (ipAddress.value === "") {
      errors.ipAddress = "Please enter ip";
    } else {
      if (ipformat.test(ipAddress.value) === false) {
        errors.ipAddress = "Please enter valid Ip";
      }
    }

    return errors;
  };
  return (
    <Formik
      initialValues={{
        ipAddress: ipAddress.value,
      }}
      validate={(values) => handleValidation(values)}
      onSubmit={(values, { setSubmitting }) => {
        addIp();
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,

        handleSubmit,
        isSubmitting,
      }) => (
        <>
          <Form>
            <div className="container-fluid">
              {/* <!-- Table filter --> */}
              <div className="row justify-content-between pt-3">
                <div className="col-12 py-2">
                  <div className="form-inline">
                    <div className="form-group px-2">
                      <label className="mr-2">IP</label>
                      <input
                        className={`form-control form-control-sm mr-2 ${
                          errors.ipAddress ? "is-invalid " : ""
                        }`}
                        ref={ipRef}
                        id="ip"
                        {...ipAddress}
                      />
                      <ErrorMessage
                        component="div"
                        name="ipAddress"
                        className="invalid-feedback f-r"
                      />
                    </div>
                    <div className="form-group px-2">
                      <button
                        className="btn btn-sm btn-outline-primary"
                        type="submit"
                      >
                        Add IP
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row pt-4 ">
                <div className="col-12">
                  <IPPagination
                    ref={reference}
                    selectRow={selectRow}
                    columns={getIPColumn}
                    path={API.ipAllocation}
                  />
                </div>
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}
