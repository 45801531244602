// import menuItems from "../../modules/MNP-Admin/pages/home/menu.json";
// handler( this.state.menuItem )
import { Link } from "react-router-dom";
import { Collapse } from "@material-ui/core";
import React, { Component } from "react";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";

const styles = `

{
    nav-link:{
        fontSize:'30px',
    }
    MuiSvgIcon-root: {
      width: 250,
    },
    links: {
      textDecoration:'none',
    }
    menuHeader: {
      paddingLeft: '30px'
    }
  };`;

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      select: window.location.pathname,
      menuItem: this.props.menuItem,
      checkFeature: this.props.checkFeature,
      featureList: this.props.featureList,
      option: "",
    };
    this.getName = this.getName.bind(this);
  }

  // this method sets the current state of a menu item i.e whether it is in expanded or collapsed or a collapsed state
  handleClick(item) {
    this.setState((prevState) => ({ [item]: !prevState[item], option: item }));
  }
  // if the menu item doesn't have any child, this method simply returns a clickable menu item that redirects to any location and if there is no child this method uses recursion to go until the last level of children and then returns the item by the first condition.

  handleSelect(se) {
    this.select = se;
  }
  findParent = (data, child) => {
    data.map((subOption) => {
      let name = subOption.name;

      if (subOption.children) {
        subOption.children.map((i) => {
          if (i.url === child) {
            this.setState({
              option: name,
            });
          }
        });
      }
    });
    return null;
  };

  findName = (data, child) => {
    let name = null;

    data.map((subOption) => {
      if (subOption.url == window.location.pathname) {
        name = subOption.name;
      }
      if (subOption.children) {
        subOption.children.map((i) => {
          if (i.url === window.location.pathname) {
            name = i.name;
          }
        });
      }
    });

    return name;
  };

  getName() {
    return this.findName(this.state.menuItem, window.location.pathname);
  }

  handleChangeOption = (subOption) => (event) => {
    if (subOption.isSubmenu) {
      this.setState({ select: subOption.url });
    } else {
      this.setState({ select: subOption.url, option: "" });
    }
  };
  handler = (children) => {
    return children.map((subOption) => {
      if (!subOption.children) {
        if (this.state.checkFeature) {
          if (!this.state.featureList.includes("Admin")) {
            if (!this.state.featureList?.includes(subOption.name)) {
              if (subOption.isFeature === false) {
                () => {};
              } else {
                return;
              }
            }
          }
        }

        return (
          <>
            <li
              className={`nav-item ${
                (this.state.select === subOption.url) |
                (this.state.select === subOption.url + "/")
                  ? "link-active "
                  : ""
              }`}
            >
              <Link
                className="nav-link"
                onClick={this.handleChangeOption(subOption)}
                to={subOption.url}
              >
                {subOption.name}
              </Link>
            </li>
            <hr />
          </>
        );
      }

      if (this.state.checkFeature) {
        if (!this.state.featureList.includes("Admin")) {
          const found = subOption.feature.some((r) =>
            this.state.featureList.includes(r)
          );
          if (subOption.feature.length != 0 && !found) {
            return;
          }
        }
      }

      return (
        <>
          <li
            className={`nav-item ${
              this.state.option === subOption.name ? "side-menu-active " : ""
            } `}
          >
            <a
              className="nav-link"
              onClick={() =>
                this.handleClick(
                  this.state.option === subOption.name ? "" : subOption.name
                )
              }
            >
              {subOption.name}
              {this.state.option === subOption.name ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </a>
          </li>
          <hr />

          <Collapse
            in={this.state.option === subOption.name}
            timeout="auto"
            unmountOnExit
          >
            {this.handler(subOption.children)}
          </Collapse>
        </>
      );
    });
  };

  componentDidMount() {
    this.findParent(this.state.menuItem, window.location.pathname);
  }

  render() {
    return <ul className="mr-auto">{this.handler(this.state.menuItem)}</ul>;
  }
}
export default withStyles(styles)(Sidebar);
