import axios from "axios";
import { APIConfig } from "../api/config";
import { AlertError } from "./alert-msg";

const downloadFileWithFileName = (file, path) => {
  // file=file.split("/cs/")[1]
  return axios
    .get(path, {
      params: { fileToDownload: file },
    })
    .then((response) => {
      let blob = new Blob([response.data]),
        downloadUrl = window.URL.createObjectURL(blob),
        filename = `${file.includes("_") ? file.split(/_(.+)/)[1] : file}`,
        disposition = response.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
          matches = filenameRegex.exec(disposition);

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      let a = document.createElement("a");
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
    })
    .catch((error) => AlertError(error));
};

const uploadFile = (file, onUploadProgress, path) => {
  let formData = new FormData();

  formData.append("fileToUpload", file);

  return axios.post(path, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

const getFiles = (file) => {
  return axios.get(API.fileManagement, {
    params: { fileToDownload: file },
  });
};

const getImageUrl = (file) => {
  return APIConfig.BASE_URL + API.fileManagement + `?fileToDownload=${file}`;
};

const downloadReportServer = (url, data) => {
  let params = {};
  if (data) {
    params = data;
  }
  return axios
    .get(
      url,
      {
        params: params,
        responseType: "blob",
      }

      // important
    )
    .then((response) => {
      let filename = "";
      let blob = new Blob([response.data]),
        downloadUrl = window.URL.createObjectURL(blob),
        disposition = response.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
          matches = filenameRegex.exec(disposition);

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      let a = document.createElement("a");
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
    })
    .catch((error) => AlertError(error));
};

// axios({
//     url: 'http://localhost:5000/static/example.pdf',
//     method: 'GET',
//     responseType: 'blob', // important
//   }).then((response) => {
//     const url = window.URL.createObjectURL(new Blob([response.data]));
//     const link = document.createElement('a');
//     link.href = url;
//     link.setAttribute('download', 'file.pdf');
//     document.body.appendChild(link);
//     link.click();
//   });

export default {
  uploadFile,
  getFiles,
  downloadFileWithFileName,
  getImageUrl,
  downloadReportServer,
};
