import { useNavigate, useLocation } from "react-router-dom";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    return (
      <Component history={useNavigate()} location={useLocation()} {...props} />
    );
  };

  return Wrapper;
};
