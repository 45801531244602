import React from "react";
import "./not-found.css";

const NotFound = () => (
  <div className="container-fluid" style={{ height: "calc(100vh - 110px)" }}>
    <div className="NotFound">
      <h3>Sorry, page not found!</h3>
    </div>
  </div>
);

export default NotFound;
