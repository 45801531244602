import React, { useEffect, useRef, useState } from "react";
import PaymentPagination from "./payment-pagination";

import { API } from "../../config";
import { FINANCIAL_PENDING, getPaymentColumn } from "../../constant";
import Select from "react-dropdown-select";
import useInput from "../../../../utils/components/custome-hooks/use-input";
import { addPayment, getApiCallDynamic } from "../../server-call/event-api";

export default function Payment(props) {
  let reference = useRef(null);
  let paymentRef = useRef(null);

  let [paymentType] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let [trxId] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let [amount] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let paymentTypeList = [
    { name: "Bkash" },
    { name: "Nagad" },
    { name: "Cash" },
    { name: "Bank Deposite or cheque" },
  ];

  const handleSelectedRow = (row, isSelect, rowIndex, e) => {
    // console.log(
    //   "row====>",
    //   row,
    //   "isSelected=>>>>",
    //   isSelect,
    //   "rowIndex=>>>>>>>",
    //   rowIndex,
    //   "Event=>>>>",
    //   e
    // );
    // if (e.target.id === "view") {
    //   setShow(true);
    //   setData(row);
    //   setStatus(true);
    //   setPermission("view");
    // } else if (e.target.id === "update") {
    //   setShow(true);
    //   setData(row);
    //   setPermission("update");
    //   setStatus(true);
    // } else if (e.target.id === "delete") {
    //   let data = {
    //     action: "delete",
    //     payload: {
    //       name: row.name,
    //     },
    //   };
    //   crudRegion(data, handleClose);
    // }
  };
  const selectRow = {
    mode: "radio",
    hideSelectColumn: true, // enable hide selection column.

    clickToSelect: true,
    onSelect: handleSelectedRow,
  };

  const handleDropDownChange =
    (val, name, multi = false) =>
    (event) => {
      // console.log("drop", event, "val", val, "name", name, "multi", multi);
      if (multi) {
        if (event.length === 0) {
          val.saveValue("");

          return;
        }
        event = event[0];
      }

      val.saveValue(event.value);
    };

  const paymentAdd = (e) => {
    e.preventDefault();
    let data = {
      action: "create",
      payload: {
        amount: amount.value,
        paymentType: paymentType.value,
        transactionId: trxId.value,
        comment: null,
        status: FINANCIAL_PENDING,
        paymentDate: Date.now(),
      },
    };
    addPayment(data, reference.current.retrieveList);
  };

  let [balance, setBalance] = useState(0);
  useEffect(() => {
    getApiCallDynamic({}, API.ownInfo).then((res) => {
      if (res?.status === 200) {
        let apiData = res.data.dataList[0];
        setBalance(apiData.balance);
      }
    });
  }, []);

  return (
    <div className="container-fluid">
      {/* <!-- Balance --> */}
      <div className="row py-4">
        <div className="col-12 col-md-4 m-auto py-2 text-primary text-center border shadow-sm rounded">
          <h4 className="font-weight-bold">
            Current Balance: <span>{balance}</span>৳
          </h4>
        </div>
      </div>
      {/* <!-- Table filter --> */}
      <form className="row  pt-3" onSubmit={paymentAdd}>
        <div className="col-auto py-2">
          <div className="form-inline">
            <div className="form-group mb-3  pr-2">
              <label className=" pr-1">Amount</label>
              <input
                className="form-control form-control-sm mr-2"
                placeholder="e.g. 50"
                type="number"
                {...amount}
                required
              />
            </div>

            <div className="form-group mb-3  pr-2">
              <label className="pr-2">Payment Media</label>
              <div className=" " style={{ minWidth: "220px" }}>
                <Select
                  ref={paymentRef}
                  multi={false}
                  // className={`form-control form-control-sm w-60 f-r select select-search  ${
                  //     errors.region
                  //         ? 'border border-danger is-invalid'
                  //         : ''
                  // } `}
                  className={
                    "form-control form-control-sm  select select-search  "
                  }
                  style={{ minHeight: "unset" }}
                  clearOnBlur={true}
                  clearable={true}
                  placeholder="Select..."
                  id="paymentType"
                  options={paymentTypeList.map((i) => {
                    return {
                      value: i.name,
                      label: i.name,
                    };
                  })}
                  // onChange={this.handleDropDownChange(
                  //     'country',
                  // )}
                  required
                  onChange={handleDropDownChange(paymentType, "", true)}
                />
              </div>
            </div>
            <div className="form-group mb-3  pr-2">
              <label className="mr-2"> TxnID / Reference</label>

              <input
                className=" form-control form-control-sm mr-2"
                placeholder="e.g. KFJGJOENA7"
                {...trxId}
                type="text"
                required
              />
              <small className="text-primary p-hint">
                {`Hints: Transection/ Reference/ Date`}
              </small>
            </div>
          </div>
        </div>
        <div className=" col-auto py-2">
          <div className="form-group mb-3  pr-2">
            <button className="btn btn-sm btn-outline-primary" type="submit">
              Send Request
            </button>
          </div>
        </div>
      </form>
      <hr />

      {/* <!-- Table --> */}
      <div className="row pt-5">
        <div className="col-12">
          <PaymentPagination
            ref={reference}
            selectRow={selectRow}
            columns={getPaymentColumn}
            path={API.payment}
          />
        </div>
      </div>
    </div>
  );
}
