import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import useInput from "../../../../utils/components/custome-hooks/use-input";
import { toastNotify } from "../../../../utils/alert-msg";
import { Path } from "../../config";
import { Auth } from "../../../../api/auth";

export default function UserVerify(props) {
  const [verifyCode, setVerifyCode] = useState(
    () => props.location.search.split("=")[1]
  );
  const [isVerified, setisVerified] = useState(undefined);
  useEffect(() => {
    Auth.verifyForgetCode(verifyCode).then((res) => {
      if (res && res.status === 200) {
        setisVerified(true);
      } else {
        if (res.status === 404) {
        }
        setisVerified(false);
      }
    });
  }, [verifyCode]);

  useEffect(() => {
    if (!isVerified) {
      props.history(Path.login);
    }
  }, [isVerified]);

  let [password] = useInput({
    data: "",
    status: false,
    initial: "",
  });
  let [confirmPassword] = useInput({
    data: "",
    status: false,
    initial: "",
  });
  const handleSubmit = (event) => {
    // event.preventDefault()
    if (isVerified) {
      Auth.setPassword(verifyCode, password.value).then((response) => {
        props.userHasAuthenticated(response[0], response[1]);

        if (response[0]) {
          toastNotify("success", "Password updated");
          props.history(Path.index);
        } else {
          if (response[1] && response[1].error && response[1].error.errorCode) {
            if (response[1].error.errorCode === 2005) {
              alert(response[1].error.errorMessage);
            } else {
              toastNotify("error", response[1].error.errorMessage);
            }
          } else {
            toastNotify("error", "Something went wrong. Please try Again!");
          }
        }
      });
    }
  };

  const handlePasswordValidation = (values) => {
    let errors = {};
    let count = 0;
    var regNumber = /[0-9]+/;
    var regCapitalAlpha = /[A-Z]+/;
    var regSmallAlpha = /[a-z]+/;
    var regSpecialChar = /[*@!#%&()^~{}]+/;
    if (password.value === "") {
      errors.password = "Please enter new password";
    } else {
      if (password.value.length < process.env.REACT_APP_PASSWORD_LENGTH) {
        errors.password = `Password must be at least ${process.env.REACT_APP_PASSWORD_LENGTH} digit`;
      } else {
        if (regNumber.test(password.value)) {
          count = count + 1;
        }
        if (regCapitalAlpha.test(password.value)) {
          count = count + 1;
        }
        if (regSmallAlpha.test(password.value)) {
          count = count + 1;
        }
        if (regSpecialChar.test(password.value)) {
          count = count + 1;
        }
        if (count < 3) {
          errors.password =
            "Password must contain One numbers, one capital alphabet. One small alphabet, One Special character";
        }
      }
    }
    if (password.value !== confirmPassword.value) {
      errors.confirmPassword = "Password does not match";
    }

    return errors;
  };

  const [passwordShow, setpasswordShow] = useState(false);
  const [confirmPasswordShow, setconfirmPasswordShow] = useState(false);

  return (
    <div className="container-fluid">
      <div className="row  login-design">
        <div className="col-md-3 m-auto">
          <div className="login">
            <img src="/assets/images/logo.png" className="login-logo" />
            <span className="company-name">MNP User Portal</span>
            <Formik
              initialValues={{
                password: password.value,
                confirmPassword: confirmPassword.value,
              }}
              validate={(values) => handlePasswordValidation(values)}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit();
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,

                handleSubmit,
                isSubmitting,
              }) => (
                <>
                  <Form className="">
                    <p className="mt-3">Reset your password</p>
                    <label className="float-left font-weight-bold">
                      New Password
                    </label>
                    <div
                      className={`input-group  ${
                        errors.password ? "is-invalid " : ""
                      }`}
                    >
                      <div className="input-group-prepend">
                        <i className="input-group-text fa fa-lock"></i>
                      </div>
                      <input
                        type={passwordShow ? "text" : "password"}
                        className={`form-control   ${
                          errors.password ? "is-invalid " : ""
                        }`}
                        placeholder="Type password"
                        id="password"
                        {...password}
                      />

                      <div className="input-group-prepend">
                        <i
                          className={`input-group-text fa ${
                            passwordShow ? "fa-eye-slash" : "fa-eye"
                          }`}
                          onClick={() => {
                            setpasswordShow((prev) => !prev);
                          }}
                        ></i>
                      </div>
                    </div>
                    <ErrorMessage
                      component="div"
                      name="password"
                      className="invalid-feedback text-left"
                    />
                    <label className="float-left font-weight-bold mt-3">
                      Retype New Password
                    </label>
                    <div
                      className={`input-group  ${
                        errors.confirmPassword ? "is-invalid " : ""
                      }`}
                    >
                      <div className="input-group-prepend">
                        <i className="input-group-text fa fa-lock"></i>
                      </div>
                      <input
                        className={`form-control   ${
                          errors.confirmPassword ? "is-invalid " : ""
                        }`}
                        type={confirmPasswordShow ? "text" : "password"}
                        className="form-control"
                        placeholder="Retype password"
                        id="confirmPassword"
                        {...confirmPassword}
                      />

                      <div className="input-group-prepend">
                        <i
                          className={`input-group-text fa ${
                            confirmPasswordShow ? "fa-eye-slash" : "fa-eye"
                          }`}
                          onClick={() => {
                            setconfirmPasswordShow((prev) => !prev);
                          }}
                        ></i>
                      </div>
                    </div>
                    <ErrorMessage
                      component="div"
                      name="confirmPassword"
                      className="invalid-feedback text-left"
                    />

                    <div className="form-group  text-center m-4 pt-4 ">
                      <button
                        type="submit"
                        className="btn btn-sm btn-outline-primary px-3 login-button"
                      >
                        Submit
                      </button>
                    </div>
                    {/* <div className="form-group w-100 text-center">
                                    <small>
                                        Forgot Password?{' '}
                                        <Link
                                            to="#"
                                            style={{ color: 'white' }}
                                            onClick={this.pageChange}
                                        >
                                            Click Here
                                        </Link>
                                    </small>
                                </div> */}
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
