import React, { Component } from "react";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import Dropdown from "react-dropdown";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-dropdown-select";

export default class PortCountPagination extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchFilter = this.onChangeSearchFilter.bind(this);
    this.retrieveList = this.retrieveList.bind(this);
    // this.refreshList = this.refreshList.bind(this);
    // this.setActiveTutorial = this.setActiveTutorial.bind(this);
    // this.removeAllTutorials = this.removeAllTutorials.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);

    this.state = {
      data: [],
      currentTutorial: null,
      currentIndex: -1,

      page: 1,
      count: 0,
      pageSize: 50,
      tariff: "",
      tariffList: [],
      startDate: "",
      endDate: "",
      spinner: false,
    };

    this.pageSizes = [50, 100, 250, 500, 1000];
  }

  componentDidMount() {
    // this.defaultParam()
    this.retrieveList();
  }

  onChangeSearchFilter(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  onClear = (e) => {
    this.setState(
      {
        [e.target.id]: "",
      },
      () => this.retrieveList()
    );
  };

  handleDropDownChange =
    (val, name, multi = false) =>
    (event) => {
      // console.log("drop", event, "val", val, "name", name, "multi", multi);
      if (multi) {
        if (event.length === 0) {
          this.setState({
            [val]: "",
            // [name]: ''
          });
          return;
        }
        event = event[0];
      }
      this.setState({
        [val]: event.value,
        // [name]: event.label
      });
    };

  getRequestParams(page, pageSize, startDate, endDate) {
    let params = {};

    if (startDate) {
      params["startData"] = startDate;
    }
    if (endDate) {
      params["endDate"] = endDate;
    }
    if (page) {
      params["page"] = page;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  }

  handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveList(true);
      }
    );
  }

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1,
      },
      () => {
        this.retrieveList(true);
      }
    );
  }

  retrieveList(pageChange = false) {
    this.setState({
      data: [],

      spinner: true,
    });

    let { page, pageSize, startDate, endDate } = this.state;

    if (pageChange === true) {
    } else {
      page = 1;
    }

    const params = this.getRequestParams(page, pageSize, startDate, endDate);

    axios
      .get(this.props.path, { params: params })
      .then((response) => {
        const { dataList, totalPages, currentPage } = response.data;
        this.setState({
          data: dataList,
          count: totalPages,
          spinner: false,
          page: currentPage,
        });
      })
      .catch((e) => {
        this.setState({
          data: [],
          page: 1,
          spinner: false,
        });
      });
  }

  render() {
    const {
      poId,
      data,
      currentTutorial,
      currentIndex,
      page,
      count,
      pageSize,
      startDate,
      endDate,
    } = this.state;

    const parentMethod = {
      onClear: this.onClear,
      state: this.state,
      refresh: this.retrieveList,
      handleDropDownChange: this.handleDropDownChange,
      handleChange: this.onChangeSearchFilter,
    };

    return (
      <div className="list row">
        <div className="col-md-11">
          <div className="form-inline mb-3">
            {/* <div className="form-group mb-3 d-flex pr-2 f-l">
              <label className="pr-2">Tariff </label>
              <div className=" " style={{ minWidth: "220px" }}>
                <Select
                  ref={this.countryRef}
                  multi={false}
                  // className={`form-control form-control-sm w-60 f-r select select-search  ${
                  //     errors.region
                  //         ? 'border border-danger is-invalid'
                  //         : ''
                  // } `}
                  className={
                    "form-control form-control-sm  select select-search  "
                  }
                  style={{ minHeight: "unset" }}
                  clearOnBlur={true}
                  clearable={true}
                  placeholder="Select..."
                  values={[
                    {
                      label: this.state.tariff,
                    },
                  ]}
                  id="clientId"
                  options={this.state.tariffList.map((i) => {
                    return {
                      value: i.name,
                      label: i.name,
                    };
                  })}
                  // onChange={this.handleDropDownChange(
                  //     'country',
                  // )}
                  onChange={this.handleDropDownChange("clientId", "", true)}
                />
              </div>
            </div> */}
            <div className="form-group ">
              <label className="mr-2">Date</label>
              <div className="input-group input-group-sm">
                <input
                  type="date"
                  className="form-control"
                  id="startDate"
                  onChange={this.onChangeSearchFilter}
                />
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    to
                  </span>
                </div>
                <input
                  type="date"
                  className="form-control"
                  id="endDate"
                  onChange={this.onChangeSearchFilter}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group mb-3 f-r">
          <button
            className="btn btn-sm btn-outline-primary "
            onClick={this.retrieveList}
          >
            Search
          </button>
        </div>
        <div className="col-md-12 overflow-hidden mb-2">
          <div className=" float-left">
            {"Items per page: "}
            <select onChange={this.handlePageSizeChange} value={pageSize}>
              {this.pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
          {/* <div className=" float-left ml-2">
            <i className="fa fa-download primary-color"></i>
            <span className="pl-2">Download file</span>
          </div> */}
          <div className=" float-right">
            <Pagination
              className=""
              showFirstButton
              showLastButton
              count={count}
              page={page}
              siblingCount={3}
              boundaryCount={1}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={this.handlePageChange}
            />
          </div>
        </div>

        <div className="col-md-12">
          <LoadingOverlay
            active={this.state.spinner}
            // spinner={<BounceLoader />}
            spinner={true}
            text="Please wait a moment"
          >
            <BootstrapTable
              className={"min-height scroll5"}
              keyField="name"
              data={this.state.data}
              columns={this.props.columns((page - 1) * pageSize, parentMethod)}
              selectRow={this.props.selectRow}
              filter={filterFactory()}
              noDataIndication="Table is Empty"
              // hover
              // condensed
              striped
            />
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}
