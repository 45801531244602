export const DownloadComponent = ({ state, handleFileDownload }) => {
  return (
    <>
      {state.value != null ? (
        <div className="input-group">
          <small className="c-span-round ">
            <span
              className="c-link"
              style={{ textAlign: "center" }}
              id={state.value}
              value={state.value}
              onClick={handleFileDownload}
              title="Download"
            >
              {state.value.split(/_(.+)/)[1]}
            </span>
            <span>
              <label className=" m-0 pl-1 c-link ">
                <input
                  className="d-none"
                  onClick={() => {
                    state.saveValue(null);
                  }}
                />{" "}
                <i className="fa fa-times text-danger" title="Delete"></i>
              </label>
            </span>
          </small>
        </div>
      ) : null}
    </>
  );
};
