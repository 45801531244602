const DefaultMenu = (props) => (
  <div className="row justify-content-center">
    <div className="col-md-3">
      <p>
        {" "}
        <a
          href={`http://${window.location.hostname}${
            props.mood ? "" : ":3000"
          }/mnp-admin`}
        >
          mnp-admin
        </a>
      </p>
      <p>
        {" "}
        <a
          href={`http://${window.location.hostname}${
            props.mood ? "" : ":3000"
          }/mnp-user`}
        >
          mnp-user
        </a>
      </p>

      <p>
        {" "}
        <a
          href={`http://${window.location.hostname}${
            props.mood ? "" : ":3000"
          }/a2p-admin`}
        >
          a2p-admin
        </a>
      </p>
      <p>
        {" "}
        <a
          href={`http://${window.location.hostname}${
            props.mood ? "" : ":3000"
          }/a2p-user`}
        >
          a2p-user
        </a>
      </p>
      <p>
        {" "}
        <a
          href={`http://${window.location.hostname}${
            props.mood ? "" : ":3000"
          }/a2p-btrc`}
        >
          a2p-btrc
        </a>
      </p>
      <p>
        {" "}
        <a
          href={`http://${window.location.hostname}${
            props.mood ? "" : ":3000"
          }/a2p-lea`}
        >
          a2p-lea
        </a>
      </p>
      <p>
        {" "}
        <a
          href={`http://${window.location.hostname}${
            props.mood ? "" : ":3000"
          }/a2p-operator`}
        >
          a2p-operator
        </a>
      </p>
    </div>
  </div>
);

let exportedModules = [];

if (process.env.REACT_APP_NAME === "mnp-user") {
  let MNPUser = require("./MNP-User");
  exportedModules.push(MNPUser.default);
} else if (process.env.REACT_APP_NAME === "mnp-admin") {
  let MNPAdmin = require("./MNP-Admin");
  exportedModules.push(MNPAdmin.default);
} else if (process.env.REACT_APP_NAME === "a2p-admin") {
  let A2PAdmin = require("./A2P-Admin");
  exportedModules.push(A2PAdmin.default);
} else if (process.env.REACT_APP_NAME === "a2p-user") {
  let A2PUser = require("./A2P-User");
  exportedModules.push(A2PUser.default);
} else if (process.env.REACT_APP_NAME === "a2p-btrc") {
  let A2PBTRC = require("./A2P-BTRC");
  exportedModules.push(A2PBTRC.default);
} else if (process.env.REACT_APP_NAME === "a2p-lea") {
  let A2PLEA = require("./A2P-LEA");
  exportedModules.push(A2PLEA.default);
} else if (process.env.REACT_APP_NAME === "a2p-operator") {
  let A2POperator = require("./A2P-Operator");
  exportedModules.push(A2POperator.default);
} else if (process.env.REACT_APP_NAME === "a2p-iptsp") {
  let A2PIPTSP = require("./A2P-IPTSP");
  exportedModules.push(A2PIPTSP.default);
}

export const modules = exportedModules;
