import React, { Component } from "react";
import IdleTimer from "react-idle-timer";
import { Auth } from "../api/auth";
import { IDLE_MINITUE } from "../api/config";
import { toastNotify } from "./alert-msg";

export default class IdelTimerContainer extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  render() {
    return (
      <div>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 60 * IDLE_MINITUE}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        {/* your app here */}
      </div>
    );
  }

  handleOnAction(event) {
    // console.log(
    //     'session',
    //     localStorage.getItem('time'),
    //     'ideltimer',
    //     IDLE_MINITUE,
    // )
    let time = new Date();

    // console.log(
    //     'last activate',
    //     Math.abs(time - new Date(localStorage.getItem('time'))) / 60000,
    //     'idel',
    //     Math.abs(time - new Date(localStorage.getItem('time'))) / 60000 >=
    //         IDLE_MINITUE,
    // )

    localStorage.setItem("time", time);

    // console.log('user did something', event)
  }

  handleOnActive(event) {}

  handleOnIdle(event) {
    // console.log('last active', this.idleTimer.reset())
    // console.log('user is idle', this.props.props.isAuthenticated)
    let time = new Date();

    if (
      Math.abs(time - new Date(localStorage.getItem("time"))) / 60000 >=
      IDLE_MINITUE
    ) {
      if (this.props.props.isAuthenticated) {
        toastNotify("error", "Session timout");

        this.props.props.handleLogout();
      }
    } else {
      this.idleTimer.reset();
    }
  }
}
