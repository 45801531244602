import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { Path } from "../../config";
import Sidebar from "../../../../utils/components/side-menu";
import menuItem from "./menu.json";

export default function TopMenu(props) {
  let navigate = useNavigate();
  let sideBarRef = useRef(null);

  let [name, setName] = useState("");
  useEffect(() => {
    let a = sideBarRef.current.getName();

    setName(a);
  });
  return (
    <>
      <nav className="navbar navbar-light navbar-expand-lg main-nav">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src="/assets/images/logo.png"
              height="34"
              className="d-inline-block align-top brand-image"
              alt="a2p"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <div className="p-name">
              <p className="nav-item">{name}</p>
            </div>

            {/* <!-- Side navbar --> */}
            <div className="side-nav scroll4">
              <Sidebar menuItem={menuItem} ref={sideBarRef} />
            </div>

            {/* <!-- Top navbar --> */}
            <div className="top-nav w-50">
              <ul className="navbar-nav justify-content-end">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle text-primary"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-user mr-2"></i>
                    {props.user.data.name}
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    {/* <a
                      className="dropdown-item"
                      data-toggle="modal"
                      data-target="#settings"
                    >
                      Settings
                    </a>
                    <hr /> */}
                    <a className="dropdown-item" onClick={props.handleLogout}>
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <div
        className="modal fade"
        id="settings"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="reg text-center">
              {/* <!-- Tab navbar --> */}
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Profile
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#documents"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Documents
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#passwords"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    Passwords
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#api"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    API
                  </a>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                {/* <!-- Profile Tab --> */}
                <div
                  className="tab-pane fade show active"
                  id="profile"
                  role="tabpanel"
                >
                  <form className="py-3 text-left">
                    <div className="row">
                      <div className="col col-md-4 py-1">
                        <label>First Name</label>
                      </div>
                      <div className="col col-md-8 py-1">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Md. Golam"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col col-md-4 py-1">
                        <label>Username</label>
                      </div>
                      <div className="col col-md-8 py-1">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Golam"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col col-md-4 py-1">
                        <label>Address</label>
                      </div>
                      <div className="col col-md-8 py-1">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Niketon, Dhaka"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col col-md-4 py-1">
                        <label>Mobile Number</label>
                      </div>
                      <div className="col col-md-8 py-1">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="+8801771000000"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col col-md-4 py-1">
                        <label>Email Address</label>
                      </div>
                      <div className="col col-md-8 py-1">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="golam@gmail.com"
                        />
                      </div>
                    </div>
                  </form>
                </div>

                {/* <!-- Documents Tab --> */}
                <div className="tab-pane fade" id="documents" role="tabpanel">
                  <form className="my-3">
                    <div className="row">
                      <div className="col py-1">
                        <label>Trade License</label>
                      </div>
                      <div className="col py-1">
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          placeholder="abd@example.com"
                        />
                        <small className="text-danger">
                          <i className="fa fa-close"></i>Invalid file
                        </small>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col py-1">
                        <label>TIN Certificate</label>
                      </div>
                      <div className="col py-1">
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          placeholder="01XXXXXXXXX"
                        />
                        <small className="text-danger">
                          <i className="fa fa-close"></i>Invalid file
                        </small>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col py-1">
                        <label>NID</label>
                      </div>
                      <div className="col py-1">
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          placeholder="01XXXXXXXXX"
                        />
                        <small className="text-danger">
                          <i className="fa fa-close"></i>Invalid file
                        </small>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col py-1">
                        <label>Others</label>
                      </div>
                      <div className="col py-1">
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          placeholder="01XXXXXXXXX"
                        />
                        <small className="text-danger">
                          <i className="fa fa-close"></i>Invalid file
                        </small>
                      </div>
                    </div>
                  </form>
                </div>

                {/* <!-- Passwords Tab --> */}
                <div className="tab-pane fade" id="passwords" role="tabpanel">
                  <form className="my-3">
                    <div className="row">
                      <div className="col col-md-4 py-1">
                        <label>Current</label>
                      </div>
                      <div className="col col-md-8 py-1">
                        <input
                          type="password"
                          className="form-control form-control-sm"
                          placeholder="******"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col col-md-4 py-1">
                        <label>New</label>
                      </div>
                      <div className="col col-md-8 py-1">
                        <input
                          type="password"
                          className="form-control form-control-sm"
                          placeholder="******"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col col-md-4 py-1">
                        <label>Retype</label>
                      </div>
                      <div className="col col-md-8 py-1">
                        <input
                          type="password"
                          className="form-control form-control-sm"
                          placeholder="******"
                        />
                      </div>
                    </div>
                  </form>
                </div>

                {/* <!-- API Tab --> */}
                <div className="tab-pane fade" id="api" role="tabpanel">
                  <form className="my-3 text-left">
                    <div className="row">
                      <div className="col-12 py-1">
                        <label>API Key</label>
                      </div>
                      <div className="col-12 py-1">
                        <label className="text-primary">
                          zaCELgL. 0imfnc8mVLWwsAawjYr4Rx-Af50DDqtlx
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 py-1">
                        <label>API URL</label>
                      </div>
                      <div className="col-12 py-1">
                        <label className="text-primary">
                          https://api.stripe.com/v1/charges -u
                          sk_test_BQokikJOvBiI2HlWgH4olfQ2:
                        </label>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="row">
                  <div className="col pt-3">
                    <button className="btn btn-sm btn-outline-danger">
                      Discard
                    </button>
                    <button
                      className="btn btn-sm btn-outline-primary"
                      type="submit"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
