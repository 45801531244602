import html2pdf from "html2pdf.js";

export function exportAsPDF(elementID, fileName) {
  let element = document.getElementById(elementID);
  let opt = {
    margin: 1,
    filename: fileName,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },

    pagebreak: {
      mode: ["avoid-all", "css", "legacy"],
      before: "#page2el",
      after: ["#after1", "#after2"],
    },

    jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
  };
  // html2pdf().set({
  //     pagebreak: { before: '.beforeClass', after: ['#after1', '#after2'], avoid: 'img' }
  // });
  html2pdf().from(element).set(opt).save();
}

// payslip pdf genarator
export function exportPayslipAsPDF(elementID, fileName) {
  let element = document.getElementById(elementID);
  let opt = {
    margin: 0.1,
    filename: fileName,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    pagebreak: {
      mode: ["avoid-all", "css"],
      before: "#page2el",
      after: ["#after1", "#after2"],
    },
    jsPDF: {
      unit: "in",
      format: "a4",
      orientation: "portrait",
    },
  };
  // html2pdf().set({
  //     pagebreak: { before: '.beforeClass', after: ['#after1', '#after2'], avoid: 'img' }
  // });
  html2pdf().from(element).set(opt).save();
}

export function exportAsPDFForSalarySheet(elementID, fileName) {
  let element = document.getElementById(elementID);
  let opt = {
    margin: 0.5,
    filename: fileName,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    pagebreak: {
      mode: ["avoid-all", "css"],
      before: "#page2el",
      after: ["#after1", "#after2"],
    },
    jsPDF: {
      unit: "in",
      format: "a3",
      orientation: "landscape",
    },
  };

  html2pdf().from(element).set(opt).save();
}
//added by zameer
export function exportAsPDFForCumilativeSheet(elementID, fileName) {
  let element = document.getElementById(elementID);
  let opt = {
    margin: 1,
    filename: fileName,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    pagebreak: {
      mode: ["avoid-all", "css"],
      before: "#page2el",
      after: ["#after1", "#after2"],
    },
    jsPDF: {
      unit: "in",
      format: "a3",
      orientation: "landscape",
    },
  };

  html2pdf().from(element).set(opt).save();
}

//Added by zameer
export function exportAsPDFForPaymentProcess(elementID, fileName) {
  let element = document.getElementById(elementID);
  let opt = {
    margin: 0.2,
    hMargin: 10,
    filename: fileName,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    pagebreak: {
      mode: ["avoid-all", "css"],
      before: "#page2el",
      after: ["#after1", "#after2"],
    },
    jsPDF: {
      unit: "in",
      format: "c3",
      orientation: "landscape",
    },
  };

  html2pdf().from(element).set(opt).save();
}

export function exportAsPDFtest(elementID, fileName) {
  let element = document.getElementById(elementID);
  let opt = {
    margin: 0.5,
    filename: fileName,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 1 },

    pagebreak: {
      mode: ["avoid-all", "css"],
      before: "#page2el",
      after: ["#after1", "#after2"],
    },

    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  };
  // html2pdf().set({
  //     pagebreak: { before: '.beforeClass', after: ['#after1', '#after2'], avoid: 'img' }
  // });
  html2pdf().from(element).set(opt).save();
}
