import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import useInput from "../../../../utils/components/custome-hooks/use-input";
import { toastNotify } from "../../../../utils/alert-msg";
import UploadService from "../../../../utils/file-upload-download";

import Select from "react-dropdown-select";
import LoadingOverlay from "react-loading-overlay";

import { ModalCommonHooks } from "../../../../utils/components/modal";
import { Auth } from "../../../../api/auth";
import {
  getApiCallDynamic,
  postApiCallDynamic,
} from "../../server-call/event-api";
import { API } from "../../config";
// import { Auth } from "../../../../api/auth";
export default function Profile(props) {
  let navigate = useNavigate();
  let [data, setData] = useState(null);

  let [clientName] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let [email] = useInput({
    data: "",
    status: false,
    initial: "",
  });
  let [address] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let [mobileNumber] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let [clientId] = useInput({
    data: "",
    status: false,
    initial: "",
  });
  let [userId] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  useEffect(() => {
    getApiCallDynamic({}, API.ownInfo).then((res) => {
      if (res?.status === 200) {
        let apiData = res.data.dataList[0];
        setData(apiData);
        clientName.saveValue(apiData.clientName);
        email.saveValue(apiData.email);
        address.saveValue(apiData.address);
        mobileNumber.saveValue(apiData.mobileNumber);
        clientId.saveValue(apiData.clientId);
        userId.saveValue(apiData.userId);
      }
    });
  }, []);
  let [spinner, setSpinner] = useState(false);
  let [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const supplierRef = useRef();
  const countryRef = useRef();
  const auditTypeRef = useRef();
  const userTypeRef = useRef();
  const imgRef = useRef();

  let [currentPassword] = useInput({
    data: "",
    status: false,
    initial: "",
  });
  let [newPassword] = useInput({
    data: "",
    status: false,
    initial: "",
  });
  let [confirmPassword] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  const handleChange = (event) => {
    if (event.target.files[0] === undefined) {
      return;
    }
    if (!fileChecker(event)) {
      toastNotify("warn", "File extension missmatch");

      return;
    }
    let fileSize = event.target.files[0].size / 1048576;

    if (fileSize > 200) {
      toastNotify("error", "File size can not be grater than 200mb");
      return;
    }
    let currentFile = event.target.files[0];
    setProgress(0);
    UploadService.uploadFile(currentFile, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((res) => {
        if (res.status === 200) {
          toastNotify("success", "Successfully uploaded");
          setProfileImg(res.data);

          setProgress(0);
        }
      })
      .catch((err) => {
        toastNotify("error", "failed to uploaded");

        setProgress(0);
      });

    // setProfileImg(URL.createObjectURL(event.target.files[0]))
  };
  const handleDropDownChange =
    (val, name, multi = false) =>
    (event) => {
      // console.log("drop", event, "val", val, "name", name, "multi", multi);
      if (multi) {
        if (event.length === 0) {
          val.saveValue("");

          return;
        }
        event = event[0];
      }

      val.saveValue(event.value);
    };
  const updatePassword = () => {
    let data = {
      action: "create",
      payload: {
        currentPassword: currentPassword.value,
        newPassword: newPassword.value,
      },
    };
    Auth.changePassword(data).then((response) => {
      if (response[0]) {
        props.userHasAuthenticated(response[0], response[1]);
        toastNotify("success", "Password updated successfully");
        handleClose();
      } else {
        toastNotify("error", response[1].error.errorMessage);
      }
    });
  };

  const handleSubmit = () => {
    let data = {
      action: "update",
      payload: {
        userId: userId.value,
        clientName: clientName.value,
        mobileNumber: mobileNumber.value,
        address: address.value,
      },
    };
    postApiCallDynamic(data, () => {}, API.businessUser);
  };
  const handlePasswordValidation = (values) => {
    let errors = {};

    let count = 0;
    var regNumber = /[0-9]+/;
    var regCapitalAlpha = /[A-Z]+/;
    var regSmallAlpha = /[a-z]+/;
    var regSpecialChar = /[*@!#%&()^~{}]+/;

    if (currentPassword.value === "") {
      errors.currentPassword = "Please enter your  password";
    }

    if (newPassword.value === "") {
      errors.newPassword = "Please enter new password";
    } else {
      if (newPassword.value.length < process.env.REACT_APP_PASSWORD_LENGTH) {
        errors.newPassword = `Password must be at least ${process.env.REACT_APP_PASSWORD_LENGTH} digit`;
      } else {
        if (regNumber.test(newPassword.value)) {
          count = count + 1;
        }
        if (regCapitalAlpha.test(newPassword.value)) {
          count = count + 1;
        }
        if (regSmallAlpha.test(newPassword.value)) {
          count = count + 1;
        }
        if (regSpecialChar.test(newPassword.value)) {
          count = count + 1;
        }
        if (count < 3) {
          errors.newPassword =
            "Password must contain One numbers, one capital alphabet. One small alphabet, One Special character";
        }
      }
    }
    if (newPassword.value !== confirmPassword.value) {
      errors.confirmPassword = "Password does not match";
    }

    return errors;
  };

  const handleValidation = (values) => {
    let errors = {};

    return errors;
  };
  useEffect(() => {
    currentPassword.saveValue("");
    newPassword.saveValue("");
    confirmPassword.saveValue("");
  }, [show]);

  return (
    <>
      <Formik
        initialValues={{}}
        validate={(values) => handleValidation(values)}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit();
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,

          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <Outlet />

            <div
              className="container-fluid"
              style={{ height: "calc(100vh - 110px)" }}
            >
              <div className=" row h-100">
                <div className="col-12 col-md-4 m-auto text-center card">
                  <LoadingOverlay
                    active={spinner}
                    // spinner={<BounceLoader />}
                    spinner={true}
                    text="Please wait a moment"
                  >
                    <Form className="p-3">
                      <div className="row  my-2 p-2">
                        <div className="col col-md-4 py-1">
                          <label>Name</label>
                        </div>
                        <div className="col col-md-8 py-1">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            {...clientName}
                          />
                        </div>
                      </div>
                      <div className="row  my-2 p-2">
                        <div className="col col-md-4 py-1">
                          <label>Username</label>
                        </div>
                        <div className="col col-md-8 py-1">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            {...clientId}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row  my-2 p-2">
                        <div className="col col-md-4 py-1">
                          <label>Address</label>
                        </div>
                        <div className="col col-md-8 py-1">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            {...address}
                          />
                        </div>
                      </div>
                      <div className="row  my-2 p-2">
                        <div className="col col-md-4 py-1">
                          <label>Mobile Number</label>
                        </div>
                        <div className="col col-md-8 py-1">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            {...mobileNumber}
                          />
                        </div>
                      </div>
                      <div className="row  my-2 p-2">
                        <div className="col col-md-4 py-1">
                          <label>Email Address</label>
                        </div>
                        <div className="col col-md-8 py-1">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            {...email}
                            disabled
                          />
                        </div>
                      </div>
                      <div>
                        <a
                          href="#"
                          className="my-2"
                          onClick={() => {
                            setShow(true);
                          }}
                        >
                          Change Password
                        </a>
                      </div>
                      <div>
                        <button
                          className="btn btn-sm  my-3 btn-outline-primary"
                          onClick={handleSubmit}
                        >
                          Save
                        </button>
                      </div>
                    </Form>
                  </LoadingOverlay>

                  {/* <!-- Modal Create --> */}
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
      <ModalCommonHooks
        load={
          <Formik
            initialValues={{
              currentPassword: currentPassword.value,
              newPassword: newPassword.value,
              confirmPassword: confirmPassword.value,
            }}
            validate={(values) => handlePasswordValidation(values)}
            onSubmit={(values, { setSubmitting }) => {
              updatePassword();
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,

              handleSubmit,
              isSubmitting,
            }) => (
              <>
                <Form className="p-3">
                  <div className="form-row">
                    <div className="form-group col-12 ">
                      <label className="w-40 text-left pr-2">
                        Current Password
                      </label>
                      <input
                        type="password"
                        className={`form-control form-control-sm w-60 f-r ${
                          errors.currentPassword ? "is-invalid " : ""
                        }`}
                        {...currentPassword}
                      />
                      <ErrorMessage
                        component="div"
                        name="currentPassword"
                        className="invalid-feedback w-60 f-r"
                      />
                    </div>
                    <div className="form-group col-12 ">
                      <label className="w-40 text-left pr-2">
                        New Password
                      </label>
                      <input
                        type="password"
                        className={`form-control form-control-sm w-60 f-r ${
                          errors.newPassword ? "is-invalid " : ""
                        }`}
                        {...newPassword}
                      />
                      <ErrorMessage
                        component="div"
                        name="newPassword"
                        className="invalid-feedback w-60 f-r"
                      />
                    </div>
                    <div className="form-group col-12 ">
                      <label className="w-40 text-left pr-2">
                        Re-type Password
                      </label>
                      <input
                        type="password"
                        className={`form-control form-control-sm w-60 f-r ${
                          errors.confirmPassword ? "is-invalid " : ""
                        }`}
                        {...confirmPassword}
                      />
                      <ErrorMessage
                        component="div"
                        name="confirmPassword"
                        className="invalid-feedback w-60 f-r"
                      />
                    </div>
                  </div>
                  <div className="modal-footer justify-content-center mt-3">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-danger"
                      onClick={handleClose}
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      className="btn btn-sm btn-outline-primary"
                    >
                      Update
                    </button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        }
        tittle={"Change Password"}
        show={show}
        setShow={handleClose}
        // size={'lg'}
      />
    </>
  );
}
