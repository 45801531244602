import React from "react";
import { customFilter } from "react-bootstrap-table2-filter";
import ConfirmModal from "../../utils/confirm-modal";
import { DateTimeFormater } from "../../utils/event-handler";
export const START_TIME = " 00:00:00.000";
export const END_TIME = " 23:59:59.999";

export let copyRightCurrentYear = process.env.REACT_APP_COPY_RIGHT_CURRENT_YEAR ? process.env.REACT_APP_COPY_RIGHT_CURRENT_YEAR : new Date().getFullYear();

export const FINANCIAL_APPROVE = "Approve";
export const FINANCIAL_REJECT = "Reject";
export const FINANCIAL_PENDING = "Pending";

export class CustomeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.filter = this.filter.bind(this);
    this.getValue = this.getValue.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.input = {
      value: "",
    };
  }
  getValue() {
    return this.input.value;
  }
  filter() {
    this.props.method.refresh();
  }
  onCancel(e) {
    this.filter();
  }
  onChange = (e) => {
    if (e.key === "Enter") {
      this.filter();
    }
  };

  render() {
    const { method, id } = this.props;
    const { handleChange, state, onClear } = method;
    return [
      <>
        <i
          className={
            (state[this.props.id] !== "") & (state[this.props.id] !== undefined)
              ? " search-icon pl-2 fa fa-search text-danger"
              : "search-icon pl-2  fa fa-search"
          }
          style={{ color: "#72848c" }}
        >
          <div className="mb-0 form-group">
            <input
              id={id}
              className="search-input mb-2 mt-2"
              key="input"
              value={state[id]}
              autoFocus={true}
              onChange={(e) => handleChange(e)}
              type="text"
              placeholder="Filter"
              onKeyDown={this.onChange}
              autocomplete="off"
            />
            <button
              className=" btn mr-2  btn-primary btn-sm search-icon-button"
              style={{ height: "25px", fontSize: "12px" }}
              onClick={this.filter}
            >
              Search
            </button>
            <button
              id={id}
              className=" btn ml-2 btn-secondary btn-sm"
              style={{ height: "25px", fontSize: "12px" }}
              onClick={onClear}
            >
              Clear
            </button>
          </div>
        </i>
      </>,
    ];
  }
}

export function getDeepingSearchPortCountColumn(offset = 0, method) {
  return [
    {
      dataField: "index",
      text: "SL No.",
      headerStyle: {
        boxShadow:
          "0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)",
      },
      formatter: (cell, row, rowIndex, colIndex) => {
        return <> {`${offset + rowIndex + 1}`}</>;
      },
      footer: (columnData) => "Total",
    },
    {
      dataField: "clientId",
      text: "Client ID",
      headerStyle: {
        boxShadow:
          "0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)",
      },
      footer: "",
      // filter: customFilter(),
      // filterRenderer: (onFilter, column) => (
      //   <CustomeFilter
      //     id="name"
      //     method={method}
      //     onFilter={onFilter}
      //     column={column}
      //   />
      // ),
    },
    {
      dataField: "logDate",
      text: "Date",
      headerStyle: {
        boxShadow:
          "0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)",
      },
      footer: "",

      // filter: customFilter(),
      // filterRenderer: (onFilter, column) => (
      //   <CustomeFilter
      //     id="name"
      //     method={method}
      //     onFilter={onFilter}
      //     column={column}
      //   />
      // ),
    },
    {
      dataField: "count",
      text: "Count",
      headerStyle: {
        boxShadow:
          "0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)",
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),

      // filter: customFilter(),
      // filterRenderer: (onFilter, column) => (
      //   <CustomeFilter
      //     id="email"
      //     method={method}
      //     onFilter={onFilter}
      //     column={column}
      //   />
      // ),
    },

    {
      dataField: "tariffName",
      text: "Tariff Name",
      headerStyle: {
        boxShadow:
          "0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)",
      },
      footer: "",
    },
    {
      dataField: "amount",
      text: "Amount",
      headerStyle: {
        boxShadow:
          "0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)",
      },
      footer: (columnData) =>
        columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
    },
  ];
}
export function getPaymentColumn(offset = 0, method) {
  return [
    {
      dataField: "index",
      text: "SL No.",
      headerStyle: {
        boxShadow:
          "0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)",
      },
      formatter: (cell, row, rowIndex, colIndex) => {
        return <> {`${offset + rowIndex + 1}`}</>;
      },
    },

    {
      dataField: "amount",
      text: "Amount",
      headerStyle: {
        boxShadow:
          "0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)",
      },

      // filter: customFilter(),
      // filterRenderer: (onFilter, column) => (
      //   <CustomeFilter
      //     id="name"
      //     method={method}
      //     onFilter={onFilter}
      //     column={column}
      //   />
      // ),
    },
    {
      dataField: "bonus",
      text: "Bonus",
      headerStyle: {
        boxShadow:
          "0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)",
      },
    },

    {
      dataField: "paymentType",
      text: "Payment Type",
      headerStyle: {
        boxShadow:
          "0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)",
      },
    },
    {
      dataField: "transactionId",
      text: "Transaction ID",
      headerStyle: {
        boxShadow:
          "0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)",
      },
    },

    {
      dataField: "paymentDate",
      text: "Date",
      headerStyle: {
        boxShadow:
          "0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)",
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: {
        boxShadow:
          "0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)",
      },
    },

    {
      dataField: "comment",
      text: "Admin Comment",
      headerStyle: {
        boxShadow:
          "0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)",
      },
      formatter: (cell, row, rowIndex, colIndex) => {
        return <> {`${cell == null || cell === "" ? "No comment" : cell}`}</>;
      },
    },
  ];
}

export function getIPColumn(offset = 0, method) {
  return [
    {
      dataField: "index",
      text: "SL No.",
      headerStyle: {
        boxShadow:
          "0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)",
      },
      formatter: (cell, row, rowIndex, colIndex) => {
        return <> {`${offset + rowIndex + 1}`}</>;
      },
    },

    {
      dataField: "ipAddress",
      text: "IP Lists",
      headerStyle: {
        boxShadow:
          "0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)",
      },

      // filter: customFilter(),
      // filterRenderer: (onFilter, column) => (
      //   <CustomeFilter
      //     id="name"
      //     method={method}
      //     onFilter={onFilter}
      //     column={column}
      //   />
      // ),
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: {
        boxShadow:
          "0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)",
      },

      // filter: customFilter(),
      // filterRenderer: (onFilter, column) => (
      //   <CustomeFilter
      //     id="email"
      //     method={method}
      //     onFilter={onFilter}
      //     column={column}
      //   />
      // ),
    },
  ];
}
